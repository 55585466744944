<template>
  <div>
    <!-- 服务合同 -->
    <div class="pa-24">
      <Content class="mt-32" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Content from "./constant/content";

export default defineComponent({
  name: "service-contract",
  components: {
    Content
  },
  setup() {}
});
</script>
