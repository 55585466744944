<template>
  <div class="mx-12">
    <div class="signedStatus">
      <a-row
        :gutter="[24, 24]"
        type="flex"
        justify="space-between"
        class="bdrs-4"
      >
        <div
          v-for="(item, index) in serverList"
          :key="index"
          style="width: 266px;border-radius:4px;"
          class="bg-white"
        >
          <a-col :span="24" class="pa-12">
            <div
              class="mt-20"
              style="width: 80px;height: 96px;margin: 0 auto;position:relative;"
              @click="handlePreviewContract(item)"
              @mouseenter="handlePreview(item)"
              @mouseleave="handlePreviewLeave(item)"
            >
              <img src="@/assets/image/contract.png" />
              <div
                v-show="item.is_show"
                style="width: 80px;height: 96px;position:absolute;top:0;left:0;color:#fff;text-align:center;cursor:pointer;"
              >
                <img src="@/assets/image/view.png" />
                <div
                  style="width: 100%;height: 100%;position:absolute;top:0;left:0;"
                >
                  <EyeOutlined class="mt-28" />
                  <p>预览合同</p>
                </div>
              </div>
            </div>
            <p class="text-center mt-24 fz-14" style="color:#4D4D4D">
              {{ item.name }}
            </p>
            <div class="mt-24 px-12 fz-12  signedStatus">
              <ul>
                <li class="d-flex justify-space-between">
                  <span style="color:#C1C1C1">签署人:</span
                  ><span style="color:#808080">{{
                    item.signatory || "-"
                  }}</span>
                </li>
                <li class="d-flex justify-space-between mt-8">
                  <span style="color:#C1C1C1">签署时间:</span
                  ><span style="color:#808080">{{
                    item.sign_time || "-"
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="d-flex justify-space-between mt-36">
              <p
                @click="handleDownload(item)"
                style="width: 50%;cursor: pointer;"
                class="text-center"
                :class="
                  item.sign_time !== null && downUrl
                    ? 'signedStatus'
                    : 'signedStatusing'
                "
              >
                下载
              </p>
              <p
                v-if="$store.getters.roles.includes('ViewerContract')"
                @click="handleServerClick(item)"
                style="width: 50%;cursor: pointer;"
                class="text-center"
                :class="
                  item.sign_time !== null ? 'signedStatusing' : 'signedStatus'
                "
              >
                {{ item.sign_time === null ? "去签署" : "已签署" }}
              </p>
            </div>
          </a-col>
        </div>
        <div style="width:266px;height:200px;"></div>
        <div style="width:266px;height:200px;"></div>
        <div style="width:266px;height:200px;"></div>
        <div style="width:266px;height:200px;"></div>
        <div style="width:266px;height:200px;"></div>
        <div style="width:266px;height:200px;"></div>
      </a-row>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { EyeOutlined } from "@ant-design/icons-vue";
import { useContractSignPageApi } from "@/apis/contract";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  components: {
    EyeOutlined
  },
  setup() {
    const router = useRouter();
    const serverList = ref([]);
    const downUrl = ref(false);

    const contractSignPage = useContractSignPageApi();

    (async () => {
      const data = await contractSignPage({});
      data.list.map(item => {
        item.is_show = false;
        if (item.sign_time !== null) {
          item.sign_time = formatToDateTime(+item.sign_time, "yyyy.MM.dd");
        }
        if (item.ext_json !== null) {
          if ("pdf_url" in item.ext_json) {
            downUrl.value = true;
          }
        }
      });
      serverList.value = data.list;
    })();

    // 点击预览合同
    const handlePreviewContract = item => {
      if (item.ext_json?.pdf_url) {
        window.open(item.ext_json.pdf_url);
      } else {
        router.push({
          path: "viewer-contract",
          query: {
            companyId: item.company_id,
            id: item.id,
            isPreview: 0
          }
        });
      }
    };

    // 合同icon划入效果
    const handlePreview = item => {
      item.is_show = true;
    };

    const handlePreviewLeave = item => {
      item.is_show = false;
    };

    // 下载
    const handleDownload = item => {
      if (item.ext_json.pdf_url) {
        window.open(item.ext_json.pdf_url);
      }
    };

    // 签署合同
    const handleServerClick = item => {
      if (item.sign_time === null) {
        router.push({
          path: "viewer-contract",
          query: { companyId: item.company_id, id: item.id, isPreview: 1 }
        });
      }
    };

    return {
      downUrl,
      serverList,
      handlePreview,
      handleDownload,
      formatToDateTime,
      handleServerClick,
      handlePreviewLeave,
      handlePreviewContract
    };
  }
});
</script>
<style lang="less" scoped>
.signedStatus {
  color: #808aa4;
}
.signedStatusing {
  color: #d4d4d4;
}
.signedStatus:hover {
  color: #1684fb;
}
</style>
